<template>
  <b-row>
    <b-col sm="12" md="12">
      <b-form @submit.prevent>
        <b-card>
          <b-card-body>
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormObserver"
            >
              <b-form
                @submit.prevent="handleSubmit(onSubmit)"
                @reset.prevent="resetForm"
              >
                <div class="demo-inline-spacing">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    @click="changetype(0)"
                    :variant="appData.type == 0 ? 'primary' : 'flat-primary'"
                  >
                    {{ $t("fields.cylinderconnection") }}
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    @click="changetype(1)"
                    :variant="appData.type == 1 ? 'primary' : 'flat-primary'"
                  >
                    {{ $t("fields.pipedconnection") }}
                  </b-button>
                </div>
                <b-row>
                  <b-col sm="12" md="6" class="mt-1">
                    <label> {{ $t("fields.houseaddress") }}</label>
                    <inputvselect
                      name="houseaddress"
                      :label="(reldata) => reldata.data.address"
                      :valuex="appData.houseaddressid"
                      keys="key"
                      @updatedata="
                        (val) => {
                          updatedetails('houseaddress', val);
                        }
                      "
                      :options="realestateaddressval"
                      id="houseaddress"
                      validations="required"
                    ></inputvselect>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1">
                    <label> {{ $t("fields.holdername") }}</label>
                    <inputvselect
                      name="holdername"
                      :label="
                        (reldata) =>
                          reldata.name
                      "
                      :valuex="appData.holdernameid"
                      keys="key"
                      @updatedata="(val) => updatedetails('holdernameid', val)"
                      :options="familymembers"
                      id="holdername"
                      validations="required"
                    ></inputvselect>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1" v-if="appData.type == 0">
                    <label> {{ $t("fields.serviceprovider") }}</label>
                    <inputselect
                      name="serviceprovider"
                      :tooltip="
                        $t('fields.select') + ' ' + $t('fields.serviceprovider')
                      "
                      :placeholder="
                        $t('fields.select') + ' ' + $t('fields.serviceprovider')
                      "
                      @updatedata="
                        (val) => {
                          appData.serviceproviderid = val;
                          appData.serviceprovider = val;
                        }
                      "
                      :valuex="appData.serviceproviderid"
                      keys="key"
                      :options="['HP Gas', 'Indane Gas', 'Bharat Gas']"
                      id="state"
                      validations="required"
                    ></inputselect>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="
                      ['HP Gas', 'Indane Gas'].includes(
                        appData.serviceproviderid
                      )
                    "
                  >
                    <inputtext
                      name="customerid"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.customerid')
                      "
                      :label="$t('fields.customerid')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.customerid')
                      "
                      :valuex="appData.customerid"
                      @updatedata="
                        (val) => {
                          appData.customerid = val;
                        }
                      "
                      validations="required|alpha-num"
                    ></inputtext>
                  </b-col>
                  <b-col
                    sm="12"
                    md="6"
                    class="mt-1"
                    v-if="['Bharat Gas'].includes(appData.serviceproviderid)"
                  >
                    <inputtext
                      name="crnnumber"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.crnnumber')
                      "
                      :label="$t('fields.crnnumber')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.crnnumber')
                      "
                      :valuex="appData.customerid"
                      @updatedata="
                        (val) => {
                          appData.customerid = val;
                        }
                      "
                      validations="required|alpha-num"
                    ></inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1" v-if="appData.type == 0">
                    <inputtext
                      name="agencyname"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.agencyname')
                      "
                      :label="$t('fields.agencyname')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.agencyname')
                      "
                      :valuex="appData.agencyname"
                      @updatedata="(val) => (appData.agencyname = val)"
                      validations=""
                    ></inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1" v-if="appData.type == 1">
                    <label> {{ $t("fields.serviceprovider") }}</label>
                    <inputvselect
                      name="serviceprovider"
                      :tooltip="
                        $t('fields.select') + ' ' + $t('fields.serviceprovider')
                      "
                      :placeholder="
                        $t('fields.select') + ' ' + $t('fields.serviceprovider')
                      "
                      :label="(reldata) => reldata.name"
                      :valuex="appData.serviceproviderid"
                      keys="key"
                      @updatedata="
                        (val) => {
                          manageserviceprovider(val);
                        }
                      "
                      :options="gasvalldata"
                      id="state"
                      validations="required"
                    ></inputvselect>
                  </b-col>
                  <b-col
                    v-if="
                      appData.serviceprovider &&
                      appData.serviceprovider.data &&
                      appData.type == 1
                    "
                    sm="12"
                    md="6"
                    class="mt-1"
                  >
                    <inputtext
                      :name="appData.serviceprovider.data.callname"
                      :tooltip="
                        $t('fields.enter') +
                        ' ' +
                        appData.serviceprovider.data.callname
                      "
                      :label="appData.serviceprovider.data.callname"
                      :placeholder="
                        $t('fields.enter') +
                        ' ' +
                        appData.serviceprovider.data.callname
                      "
                      :valuex="appData.customerid"
                      @updatedata="
                        (val) => {
                          appData.customerid = val;
                        }
                      "
                      validations="required|alpha-num"
                    ></inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1" v-if="appData.type == 0">
                    <inputtext
                      name="lpgid"
                      :tooltip="$t('fields.enter') + ' ' + $t('fields.lpgid')"
                      :label="$t('fields.lpgid')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.lpgid')
                      "
                      :valuex="appData.lpgid"
                      @updatedata="(val) => (appData.lpgid = val)"
                      validations="integer|length:17"
                    >
                    </inputtext>
                  </b-col>
                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="deposit"
                      :tooltip="$t('fields.enter') + ' ' + $t('fields.deposit')"
                      :label="$t('fields.securitydeposit')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.securitydeposit')
                      "
                      :valuex="appData.deposit"
                      @updatedata="
                        (val) => managefloat('deposit', parseFloat(val))
                      "
                      validations="integer|amount"
                    ></inputtext>
                  </b-col>
                  <!-- <b-col sm="12" md="6" class="mt-1" v-if="appData.type == 1">
                    <inputtext
                      name="customercode"
                      :tooltip="
                        $t('fields.enter') + ' ' + $t('fields.customercode')
                      "
                      :label="$t('fields.customercode')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.customercode')
                      "
                      :valuex="appData.customercode"
                      @updatedata="(val) => (appData.customercode = val)"
                      validations="required"
                    ></inputtext>
                  </b-col> -->
                  <b-col sm="12" md="6" class="mt-1">
                    <inputtext
                      name="remarks"
                      :tooltip="$t('fields.enter') + ' ' + $t('fields.remarks')"
                      :label="$t('fields.remarks')"
                      :placeholder="
                        $t('fields.enter') + ' ' + $t('fields.remarks')
                      "
                      :valuex="appData.remarks"
                      @updatedata="(val) => (appData.remarks = val)"
                      validations=""
                    ></inputtext>
                  </b-col>
                  <b-col sm="12" v-if="edittype == 0">
                    <h5 class="mt-2">{{ $t("fields.fileupload") }}</h5>
                    <b-row>
                      <b-col sm="12" md="6" class="mt-1" lg="6">
                        <validation-provider
                          #default="validationContext"
                          name="Upload File"
                          rules=""
                        >
                          <b-form-group
                             :label="$t('fields.uploadfile')"
                            label-for="fileinput"
                          >
                            <b-form-file
                              accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf"
                              id="fileinput"
                              name="Upload File "
                              :placeholder="$t('fields.uploadfile')"
                              drop-placeholder="Drop file here..."
                              @change="updateValue($event)"
                            />
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col sm="12">
                    <div class="d-flex float-right">
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        class="mr-1"
                        variant="secondary"
                        @click="$router.go(-1)"
                      >
                        <feather-icon icon="ArrowLeftIcon" class="mr-25" />
                        <span class="align-middle"
                          >{{ $t("fields.back") }}
                        </span>
                      </b-button>
                      <b-button
                        v-if="edittype == 1"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-1"
                        :to="{
                          name: GENAPP_APP_STORE_MODULE_NAME + '-view',
                          params: { id: iddata },
                        }"
                      >
                        <feather-icon icon="EyeIcon" class="mr-25" />
                        {{ $t("fields.view") }}
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary                            "
                        class="mr-1"
                        type="submit"
                      >
                        <feather-icon icon="Edit3Icon" class="mr-25" />
                        {{ $t("fields.save") }}
                      </b-button>
                    </div></b-col
                  >
                </b-row>
              </b-form>
              <!--/ form -->
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-form>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BFormDatepicker,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormFile,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import inputvselect from "@/views/Component/Input/inputvselect.vue";
import inputselect from "@/views/Component/Input/inputselect.vue";
import inputcleaveminmax from "@/views/Component/Input/inputcleaveminmax.vue";
import inputtext from "@/views/Component/Input/inputtext.vue";
import inputtextarea from "@/views/Component/Input/inputtextarea.vue";
import { required, alphaNum } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import StoreModule from "./StoreModule";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
export default {
  components: {
    BCol,
    BCard,
    BRow,
    BForm,
    flatPickr,
    BCardBody,
    BFormGroup,
    inputselect,
    inputvselect,
    inputtextarea,
    inputtext,
    BFormCheckbox,
    BFormInput,
    BFormFile,
    BFormInvalidFeedback,
    BButton,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    vSelect,
    inputcleaveminmax,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
    };
  },
  setup(props, { emit }) {
    const GENAPP_APP_STORE_MODULE_NAME = "utilities-gas";
    const modulename = "Gas";
    const modulenamesub = "gas";
    // Register module
    if (!store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
      store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
    const toast = useToast();
    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });
    let today = new Date();

    let key = 0;
    let edittype = 0;
    let storetype = "addData";
    let iddata = router.currentRoute.params.id;
    if (iddata != undefined) {
      edittype = 1;
      storetype = "editData";
    }
    const blankAppData = {
      name: "",
      type: 0,
      holdernameid: "",
      holdername: "",
      stateid: "",
      cylcustomerid: "",
      agencyname: "",
      serviceproviderid: "",
    //   customercode: "",
      serviceprovider: "",
      address: "",
      houseaddressid: "",
      houseaddress: "",
      crnnumber: "",
      customerid: "",
      lpgid: "",
      deposit: 0,
      remarks: "",
      fileData: {
        name: "",
        file: "",
        size: "",
        type: "",
      },
    };
    const appData = ref(JSON.parse(JSON.stringify(blankAppData)));
    var tempcatch = 1;

    const onSubmit = () => {
      let temp = appData.value;
      let formData = new FormData();

      let appdatax = {
        customvalue: temp.serviceprovider != "Others" ? 0 : 1,
        finalserviceprovider: temp.serviceprovider.hasOwnProperty("data")
          ? temp.serviceprovider.name
          : temp.serviceprovider || "",
        customerid: temp.customerid,
        type: temp.type,
        serviceproviderid: temp.serviceproviderid || "",
        serviceprovider: temp.serviceproviderid
          ? temp.serviceprovider
            ? temp.serviceprovider.name
            : "" || ""
          : null,
        name: temp.callname,
        houseaddressid: temp.houseaddressid,
        houseaddress: temp.houseaddress.data.address,
        lpgid: temp.lpgid,
        address: temp.address,
        holdernameid: temp.holdernameid,
        cylcustomerid: temp.cylcustomerid,
        agencyname: temp.agencyname,
        deposit: temp.deposit,
        holdername: temp.holdername,
        remarks: temp.remarks,
        callname: temp.serviceproviderid
          ? temp.type == 1
            ? temp.serviceprovider.data.callname
            : temp.serviceprovider
          : "",
      };

      let metadataappdatax = {
        customvalue: temp.serviceprovider != "Others" ? 0 : 1,
        serviceproviderid: temp.serviceproviderid || "",
        finalserviceprovider: temp.serviceprovider.hasOwnProperty("data")
          ? temp.serviceprovider.name
          : temp.serviceprovider || "",
        customerid: temp.customerid,
        holdername: temp.holdername,
        houseaddressid: temp.houseaddressid,
        holdernameid: temp.holdernameid,
        callname: temp.serviceproviderid
          ? temp.type == 1
            ? temp.serviceprovider.data.callname
            : temp.serviceprovider
          : "",
      };

      if (edittype == 1) {
        var jx = {
          id: router.currentRoute.params.id || null,
          customvalue: metadataappdatax.customvalue,
          data: appdatax,
          name: temp.callname,
          metadata: metadataappdatax,
        };
      } else {
        formData.append("name", temp.callname);
        formData.append("customvalue", metadataappdatax.customvalue);
        formData.append("data", JSON.stringify(appdatax));
        formData.append("metadata", JSON.stringify(metadataappdatax));

        if (temp.fileData.file) {
          let file = temp.fileData.file;
          formData.append("file", file);
        }
      }
      if (tempcatch) {
        tempcatch = 0;
        store
          .dispatch(
            GENAPP_APP_STORE_MODULE_NAME + "/" + storetype,
            edittype == 0 ? formData : jx
          )
          .then((response) => {
            if (response.data.success) {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  text:
                    modulename +
                    (edittype == 1 ? " editted" : " added") +
                    " successfully",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              router
                .push({
                  name: GENAPP_APP_STORE_MODULE_NAME,
                  params: {
                    id: response.data.id,
                  },
                })
                .catch(() => {});
            } else if (response.data.success == 0) {
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  variant: "error",
                },
              });
            }
          })
          .catch((error) => {
            tempcatch = 1;

            if (error.response.status === 404) {
              appData.value = undefined;
              toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  text: "Couldnt find " + modulenamesub + " details",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          });
      }
    };
    const familymembers = ref([]);
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchefamilymemberdetails")
      .then((response) => {
        familymembers.value = response.data.data;
      });

    const realestateaddressval = ref([]);
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetcherealestate")
      .then((response) => {
        realestateaddressval.value = response.data.data;
      });
    const gasvalldata = ref([]);
    store
      .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchgasData")
      .then((response) => {
        gasvalldata.value = response.data.data;
      });
    if (iddata != undefined) {
      store
        .dispatch(GENAPP_APP_STORE_MODULE_NAME + "/fetchData", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          if (!response.data.data) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Couldnt find " + modulenamesub + " details",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            router
              .push({
                name: GENAPP_APP_STORE_MODULE_NAME,
              })
              .catch(() => {});
          }

          appData.value = response.data.data.data;
          appData.value.name = response.data.data.name;
          key = Math.random();
        })
        .catch((error) => {
          if (error.response.status === 404) {
            appData.value = undefined;
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Couldnt find " + modulenamesub + " details",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else if (error.response.status === 500) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Input fields missing values",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else if (error.response.status === 422) {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Please refresh your page",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    }
    const resetappData = () => {
      appData.value = JSON.parse(JSON.stringify(blankAppData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetappData);
    return {
      GENAPP_APP_STORE_MODULE_NAME,
      edittype,
      iddata,
      appData,
      modulename,
      modulenamesub,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      key,
      realestateaddressval,
      familymembers,
      tempcatch,
      gasvalldata,
      today,
    };
  },
  mounted() {},
  methods: {
    updatedetails(tit, val) {
      if (tit == "holdernameid") {
        this.appData.holdernameid = val.id;
        this.appData.holdername = val.name;
      } else if (tit == "houseaddress") {
        this.appData.houseaddressid = val ? val.id : null;
        this.appData.houseaddress = val;
        this.appData.holdernameid = val ? val.data.holdernameid : null;
      }
    },
    managefloat(name, val) {
      this.$set(this.appData, name, parseFloat(val));
    },
    managestate(val) {
      if (val != this.appData.state) {
        this.appData.stateid = val ? val.id : null;
        this.appData.state = val;
        if (val) {
          this.waterDataget();
        }
      }
      if (!val) {
        this.gasvalldata = [];
        this.appData.customerid = null;
      }
    },
    manageserviceprovider(val) {
      if (val != this.appData.serviceprovider) {
        this.appData.serviceprovider = val;
        this.appData.serviceproviderid = val ? val.id : null;
      }
      if (!val) {
        this.appData.customerid = null;
      }
    },
    managecliserviceprovider(val) {
      if (val != this.appData.serviceprovider) {
        this.appData.serviceprovider = val;
        this.appData.serviceproviderid = val ? val.id : null;
      }
      if (!val) {
        this.appData.customerid = null;
      }
    },
    changetype(type) {
      this.appData.callname = "";
      this.appData.type = "";
      this.appData.readingdate = "";
      this.appData.serviceprovname = "";
      this.appData.serviceprov = "";
      this.appData.holdername = "";
      this.appData.address = "";
      this.appData.deposit = "";
      this.appData.acountid = "";
      this.appData.meterno = "";
      this.appData.meterreading = "";
      this.appData.consumerno = "";
      this.appData.lpgid = "";
      this.appData.type = type;
    },
    updateValue(value) {
      if (value.target.files[0].size <= 2 * 1048576) {
        this.appData.fileData.file = value.target.files[0];
        this.appData.fileData.name = value.target.files[0].name;
        this.appData.fileData.size = value.target.files[0].size;
        this.appData.fileData.type = value.target.files[0].type;
      } else {
        this.$swal({
          icon: "error",
          title: "File limit Reached!",
          text: "File size must be maximum 2 mb .",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
